import React, { useState } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import ArticleFeatured from '../components/article-featured'
import Masonry from 'react-masonry-css'
import PreFooter from '../components/pre-footer';
import InfiniteScroll from 'react-infinite-scroll-component';

// import * as contentful from "contentful";

export default ({ data, location}) => {

  const siteTitle = get(data, 'site.siteMetadata.title')
  const postsData = get(data, 'allContentfulBlogPost.edges')
  const homepage  = get(data, 'contentfulHomepage')
  const person    = get(data, 'contentfulPerson')
  
  const { headline, subhead } = homepage;
  const heroData = { headline, subhead };

  const breakpointColumnsObj = { default: 2, 1024: 1 };

  // const client = contentful.createClient({
  //   space: process.env.CONTENTFUL_SPACE_ID,
  //   accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
  // });

  const [postsToSkip, setPostsToSkip]  = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [posts, setPosts]              = useState([...postsData.slice(0,2)]);
  const getPostsClientSide = () => {
    if(posts.length >= postsData.length){
      setHasMore(false)
      return;
    }
    setPosts([...posts, ...postsData.slice(postsToSkip, postsToSkip+2)])
    setPostsToSkip(postsToSkip + 2)
    console.log(posts);
  }

  return (
    <Layout location={location}>
      <Helmet title={siteTitle} />
      <main id="MainContent" style={{ background: '#fff' }}>
        <Hero data={heroData}/>
        <ArticleFeatured article={homepage.featuredPost}/>
        <div className="articles">
          <h2 className="section-headline center">articles</h2>
          <div className="article-list">
            <InfiniteScroll
              dataLength={posts.length} //This is important field to render the next data
              next={getPostsClientSide}
              hasMore={hasMore}
              loader={
                <h4 className="loading" >Loading...</h4>
              }
            >
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-grid_column">
                  
                  { posts && posts.map(({ node }) => {
                    return (
                      <ArticlePreview key={node.slug} article={node} />
                    )
                  })}
              </Masonry>
            </InfiniteScroll>
          </div>
        </div>
        <PreFooter data={person}/>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulHomepage {
      headline
      subhead
      featuredPost {
        title
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
        previewImage{
          fluid(maxWidth: 800, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, skip: 1) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM D")
          readTime
          tags
          previewImage {
            fluid(maxWidth: 800, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    contentfulPerson(contentful_id: {eq: "15jwOBqpxqSAOy2eOO4S0m"}) {
      image {
        fluid(maxWidth: 800, resizingBehavior: SCALE ) {
          ...GatsbyContentfulFluid
        }
      }
      shortBio {
        shortBio
      }
    }
  }
`
