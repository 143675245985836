import Img from 'gatsby-image'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import '../styles/components/featured-article.scss'

import { animated, useSpring } from "react-spring";
import VisibilitySensor from "react-visibility-sensor";

export default ({article}) => {

  const [isVisible, setVisibility] = useState(false);
  
  const animatedProps = useSpring({
    transform: isVisible ? `translateY(0px)` : `translateY(100px)`,
    opacity: isVisible ? 1 : 0, 
  })
  const animatedProps_2 = useSpring({
    opacity: isVisible ? 1 : 0, 
    transform: isVisible ? `translateY(0px)` : `translateY(150px)`,
  })

  const onVisibleChange = (isVisible) =>  {
    isVisible && setVisibility(isVisible)
  }

  return (
    <VisibilitySensor partialVisibility onChange={onVisibleChange}>
      <div className="featured-article">
        <animated.div style={animatedProps} className="inner">
          <div className="feature-article-wrapper">
            <animated.div style={animatedProps_2} className="article-image">
              <div className="inner-wrapper">
                <Img alt="" fluid={article.previewImage.fluid} />
              </div>
            </animated.div>
            <div className="article-info">
              <span className="section-headline">featured</span>
              <div className="headline">{ article.title }</div>
              <div 
                className="description" 
                dangerouslySetInnerHTML={{
                  __html: article.description.childMarkdownRemark.html,
                }}
              />
            <Link className="cta-1" to={`/blog/${article.slug}`}>read more</Link>
            </div>
          </div>
        </animated.div>
      </div>
    </VisibilitySensor>
  )
}
