import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState }  from 'react';
import { animated, useSpring } from "react-spring";
import VisibilitySensor from "react-visibility-sensor";
import '../styles/components/article-preview.scss';


export default ({ article }) => {

  const [isVisible, setVisibility] = useState(false);

  const animatedProps = useSpring({
    transform: isVisible ? `translateY(0px)` : `translateY(150px)`,
    opacity: isVisible ? 1 : 0, 
  })

  const onVisibleChange = (isVisible) =>  {
    isVisible && setVisibility(isVisible)
  }

  return (
    <VisibilitySensor partialVisibility onChange={onVisibleChange}>
      <animated.div style={animatedProps} className="article-preview">
        <div className="image-wrapper">
          <Img alt="" fluid={article.previewImage.fluid} />
        </div>
        <div className="text-wrapper">
          <h3 className="title">
            <Link to={`/blog/${article.slug}`}>{article.title}</Link>
          </h3>
          <div className="excerpt"
            dangerouslySetInnerHTML={{
              __html: article.description.childMarkdownRemark.html,
            }}
            />
          {/* <Link className="read-more" to={`/blog/${article.slug}`}><span>Read</span></Link> */}
            <div className="article-eyebrow">
              {/* <span>{article.publishDate}</span> */}
              <span>{article.readTime} min read</span>
            </div>
        </div>
      </animated.div>
    </VisibilitySensor>
  )
} 
