import Img from 'gatsby-image';
import React, { useState } from 'react';
import { animated, useSpring } from "react-spring";
import VisibilitySensor from "react-visibility-sensor";
import "../styles/components/pre-footer.scss";


const PreFooter = ({data}) => {
  const [isVisible, setVisibility] = useState(false);
  
  const animatedProps = useSpring({
    transform: isVisible ? `translateY(0px)` : `translateY(100px)`,
    opacity: isVisible ? 1 : 0, 
  })

  const onVisibleChange = (isVisible) =>  {
    isVisible && setVisibility(isVisible)
  }

  return (
      <VisibilitySensor partialVisibility onChange={onVisibleChange}>
        <div className="pre-footer">
            <animated.div style={animatedProps} className="image-wrapper">
              <Img alt="" fluid={data.image.fluid} />
            </animated.div>
            <div className="text-wrapper">
              <h3 className="heading">About Me</h3>
              <p className="body">{data.shortBio.shortBio}</p>
            </div>
        </div>
      </VisibilitySensor>
  )
}

export default PreFooter;
